import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import HomePage from './components/HomePage';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import BlogPage from './components/BlogPage';
import ContactPage from './components/ContactPage';
import './App.css'; // Import the CSS file

function App() {
  return (
    <Router>
      <div className="App">
        <header>
          <img src="logo.jpg" alt="Logo" className="logo" />
          <nav>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/blog">Blogs</Link>
            <Link to="/contact">Contact</Link>
          </nav>
        </header>

        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </main>

        <footer>
          © {new Date().getFullYear()} Aarav Sinha
        </footer>
      </div>
    </Router>
  );
}

export default App;