import React from 'react';

function HomePage() {
  return (
    <div className="page">
      <h1>Welcome to my website!</h1>
      <p>
      Hello! I'm a Application security researcher with a passion for keeping the digital word safe for use.
      I have a strong background in security fundamentals and have extensive experience with real life scenarios involving mobile, cloud and web applications.
      </p>
    </div>
  );
}

export default HomePage;


